import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Storage } from '@ionic/storage-angular';
import {
  LoadingController,
  ModalController,
  NavController,
} from '@ionic/angular';
import { EmployeeloginComponent } from '../components/employeelogin/employeelogin.component';

import { environment } from '../../environments/environment';

import Swal from 'sweetalert2';
import { Router } from '@angular/router';

import { yoyoToast } from '../../../node_modules/yoyo-toast/dist/yoyoToast.min.mjs';

declare var io: any;

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public user = null;
  public openEYEModules = null;
  public token = null;
  public tokenId = null;

  public socket = null;
  public socketMemberOf = ['Group: Everyone'];
  public chatRooms = [];
  public chatUnreadMessages = false;
  public chatModal = "none";
  public chatGroupsAndUsers = [];
  public currentChat:any = {};

  chatEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private http: HttpClient,
    private storage: Storage,
    private loadingCtrl: LoadingController,
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private router: Router
  ) {

    this.socket = io.connect(environment.oldv8);
    this.socket.on('chatMessage',(data) => {
      this.chatReceiveMessage(data);
    });
  
  }

  loadStorage = async () => {
    await this.storage.create();
    if (!this.token) {
      this.token = await this.storageGet('token');
    }
    if (!this.tokenId) {
      this.tokenId = await this.storageGet('tokenId');
    }
    if (!this.user) {
      this.user = await this.storageGet('user');
    }
    if (!this.openEYEModules) {
      this.openEYEModules = await this.storageGet('openEYEModules');
    }

    // get chat data
    if (this.socketMemberOf.length == 1 && this.user) {
      // get sockerMemberOf
      this.socketMemberOf = [];
      this.http.get(`${environment.oldv8}/chat/memberof/${this.user.userName}`)
      .toPromise()
      .then((memberOfData:any) => {
        this.socketMemberOf = [...memberOfData];

        // get User rooms
        this.http.post(`${environment.oldv8}/chat/userrooms/${this.user.userName}`,{
          memberOf: this.socketMemberOf.join(",")
        })
        .toPromise()
        .then((chatRoomsData:any) => {
          this.chatRooms = [...chatRoomsData];
          this.chatRoomsSync();
        });

        // get users and groups
        this.http.get(`${environment.oldv8}/chat/groupsandusers`)
        .toPromise()
        .then((grpsusrs:any) => {
          grpsusrs.forEach(element => {
            this.chatGroupsAndUsers.push({
              code: element.user,
              name: element.userName
            });
          });
        })

      }); 
    }

  };

  storageGet = async (key: string) => {
    const value = await this.storage.get(key);
    return value;
  };

  storageSet = async (key: string, value: any) => {
    await this.storage.set(key, value);
  };

  storageDelete = async (key: string) => {
    await this.storage.remove(key);
  };

  storageClear = async () => {
    this.storage.clear();
  };

  isLoggedIn = async (moduleCode = null) => {
    await this.loadStorage();

    if (this.token) {
      if (moduleCode) {
        const result: any = await this.http
          .get(`${environment.v8}/sysadmin/accesslevel/${moduleCode}`, {
            headers: new HttpHeaders({
              Authorization: `Bearer ${this.token}`,
            }),
          })
          .toPromise();
        if (result.accessLevel == 0) {
          Swal.fire({
            icon: 'error',
            heightAuto: false,
            title: 'Access restricted',
          });
          this.navCtrl.navigateRoot('home');
        }
        return result.accessLevel;
      }
      return this.user;
    }

    const modal = await this.modalCtrl.create({
      component: EmployeeloginComponent,
      showBackdrop: false,
      backdropDismiss: false,
      swipeToClose: false,
      keyboardClose: false,
    });
    await modal.present();
    await modal.onDidDismiss();
    return this.user;
  };

  login = async (userName: string, password: string) => {
    const loading = await this.loadingCtrl.create({
      message: 'Please wait...',
    });
    await loading.present();
    try {
      const result: any = await this.http
        .post(`${environment.v8}/employee/login`, {
          userName,
          password,
        })
        .toPromise();
      loading.dismiss();

      this.user = {
        ...result.employee.userData,
        userId: result.employee.userId,
        picture: `${environment.baseDomain}/${result.employee.userData.picture}`,
      };
      await this.storageSet('user', this.user);

      this.openEYEModules = result.employee.openEYEModules;
      await this.storageSet('openEYEModules', this.openEYEModules);

      this.token = result.token;
      await this.storageSet('token', this.token);
      this.tokenId = result.tokenId;
      await this.storageSet('tokenId', this.tokenId);

      return true;
    } catch (error) {
      console.log(error.status);
      loading.dismiss();
      Swal.fire({
        icon: 'error',
        heightAuto: false,
        title: 'Invalid username/password',
      });
      return false;
    }
  };

  logout = async () => {
    try {
      const token = this.token;
      this.token = null;
      this.tokenId = null;
      this.user = null;
      this.openEYEModules = null;
      this.storageClear();
      this.http
        .get(`${environment.v8}/employee/logout`, {
          headers: new HttpHeaders({
            Authorization: `Bearer ${token}`,
          }),
        })
        .toPromise();
      this.navCtrl.navigateRoot('/login');
    } catch (error) {
      console.error(error);
    }
  };

  getSystemSettings = async (settingName = 'X') => {
    try {
      const result = await this.http
        .get(`${environment.v8}/misc/systemsetting/${settingName}`, {
          headers: new HttpHeaders({
            Authorization: `Bearer ${this.token}`,
          }),
        })
        .toPromise();
      return result;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  sendSms = async (to, msg, pid = 0) => {
    try {
      console.log(this.user);
      const loading = await this.loadingCtrl.create({
        message: 'Please wait...',
      });
      await loading.present();

      const result = await this.http
        .post(`${environment.v8}/misc/openemrsms`, {
          user: 'jvanegas',
          pid,
          to,
          msg,
        })
        .toPromise();
      Swal.fire({
        icon: 'success',
        heightAuto: false,
        title: `SMS send to ${to}`,
      });
      loading.dismiss();
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  getSupportLists = async (lists) => {
    try {
      const result: any = await this.http
        .get(`${environment.v8}/misc/list/${lists}`, {
          headers: new HttpHeaders({
            Authorization: `Bearer ${this.token}`,
          }),
        })
        .toPromise();
      return result;
    } catch (error) {
      console.error(error.error);
      this.handleHttpError(error);
      return false;
    }
  };

  async getSchema(path = '') {
    try {
      const result: any = await this.http
        .get(`${environment.v8}/${path}`, {
          headers: new HttpHeaders({
            Authorization: `Bearer ${this.token}`,
          }),
        })
        .toPromise();
      return result;
    } catch (error) {
      console.error(error.error);
      this.handleHttpError(error);
      return false;
    }
  }

  async getRoster() {
    const loading = await this.loadingCtrl.create({
      message: 'Please wait...',
    });
    await loading.present();

    try {
      const result: any = await this.http
        .get(
          `${environment.baseDomain}/utils/index.php/v8/hr/roster/${this.tokenId}`
        )
        .toPromise();
      loading.dismiss();
      return result;
    } catch (error) {
      console.error(error.error);
      loading.dismiss();
      this.handleHttpError(error);
      return false;
    }
  }

  handleHttpError(error) {
    switch (error.status) {
      case 500:
        Swal.fire({
          icon: 'error',
          heightAuto: false,
          title: 'Server error',
          text: error.error,
        });
        break;
      case 401:
        this.logout();
        break;
      case 403:
        Swal.fire({
          icon: 'error',
          heightAuto: false,
          title: 'Resource is forbidden',
          text: error.error,
        });
        break;
      case 404:
        this.navCtrl.navigateForward('/notfound');
        break;
    }
  }

  getPreviousPath() {
    try {
      if (this.router.getCurrentNavigation().previousNavigation) {
        return this.router
          .getCurrentNavigation()
          .previousNavigation.finalUrl.toString();
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  tablePrint(e, row, orientation = 'portrait') {
    const table = row.getTable();
    // table.print(false, true);
    // table.print('active', true);
    // table.print();
    table.downloadToTab('pdf', 'data.pdf', { orientation }); //convert table to PDF and open in new tab
  }

  tableExcel(e, row) {
    const table = row.getTable();
    const randomName = `report-${Math.random()}.xlsx`;
    table.download('xlsx', randomName);
  }

  tableCopyToClipBoard(e, row) {
    const table = row.getTable();
    table.copyToClipboard('active');
    Swal.fire({
      heightAuto: false,
      position: 'top-end',
      icon: 'success',
      title: 'Copied to clipboard',
      showConfirmButton: false,
      timer: 1500,
    });
  }

  chatReceiveMessage(data:any) {
      // check if msg is for user
      const msgMembers = new Set(data.chtrmMembers);
      const usrMemberOf = new Set(this.socketMemberOf);
      const intersection = [...msgMembers].filter(
          (element:any) => usrMemberOf.has(element)
      );            
      if (intersection.length > 0) {

        let room:any = {}
        let removeMsg = this.chatRooms.filter(item => {
            if (item.chtrmId != data.chtrmId) {
                return true;
            } else {
                room = {...item};
                return false;
            }
        });
        if (room.chtrmId) {
            data.chtrmactUser = room.chtrmactUser;
            data.chtrmactDate = room.chtrmactDate
        }
        this.chatRooms = [data,...removeMsg];
        this.chatRoomsSync();

        // if new msg belongs to current chat pushit
        if (this.currentChat.room && this.currentChat.room.chtrmId == data.chtrmId) {
          this.currentChat.messages.unshift({
            chtrmmsgUser: data.chtrmLastMsgUser,
            chtrmmsgUserName: data.chtrmLastMsgUserName,
            chtrmmsgMsg: data.chtrmLastMsg,
            chtrmmsgDate: data.chtrmLastMsgDate
          });
          this.chatEvent.emit(data);
        }

        // show toast?
        let showToast = false;
        if (this.chatModal == "none") {
          showToast = true;
        }
        if (this.currentChat.room) {
          if (this.currentChat.room.chtrmId != data.chtrmId) {
            showToast = true;
          }
        }

        if (showToast) {
          yoyoToast.fire({
              title: data.chtrmLastMsgUserName,
              message: data.chtrmLastMsg,
              timeout: 10000
          });
        }
      }      
  }

  chatRoomsSync() {
    this.chatRooms.map((item,idx) => {
      this.chatRooms[idx]["newMessage"] = false;
      if (item.chtrmactUser) {
        // chatroom, check if user has prev activity
        if (item.chtrmactUser != "") {
            let msgLast = new Date(item.chtrmLastMsgDate);
            let msgLastUser = new Date(item.chtrmactDate);
            if (msgLastUser < msgLast) {
              this.chatRooms[idx]["newMessage"] = true;
              this.chatUnreadMessages = true;
            }
        } else {
          // chatroom, user has no activity
          this.chatRooms[idx]["newMessage"] = true;
          this.chatUnreadMessages = true;
        }
      } else {
        // new chatroom
        this.chatRooms[idx]["newMessage"] = true;
        this.chatUnreadMessages = true;
      }
    });        
    console.log(this.chatRooms);
  }

  async chatGetRoom(chtrmId) {
    const data = await this.http.get(`${environment.oldv8}/chat/room/${chtrmId}/${this.user.userName}`).toPromise();
    this.currentChat = data;    
    console.log(this.currentChat);
    return data;
  }

  chatCloseRoom() {
    this.socket.emit('openemrIO',{
      eventName: 'chatRoomLastActivity',
      eventPayload: {
        chtrmId: this.currentChat.room.chtrmId,
        chtrmUser: this.user.userName
      }
    });
    this.chatRooms.map((item,idx) => {
      if (item.chtrmId == this.currentChat.room.chtrmId) {
          this.chatRooms[idx].chtrmactUser = this.currentChat.room.chtrmactUser;
          this.chatRooms[idx].chtrmactDate = new Date();
      }
    });
    this.currentChat = {};
    this.chatRoomsSync();
  }

  chatSendMessage(newMessage: string) {
    this.http.post(`${environment.oldv8}/chat/send`,{
      chtrm: this.currentChat.room.chtrmId,
      msg: newMessage.replace(/(?:\n|\r)/g,"<br>"),
      user: this.user.userName,
      userName: `${this.user.fname} ${this.user.lname}`
    }).toPromise();
  }

  chatSendFile(file:File) {

    let formData = new FormData();
    formData.append('chtrmUser',this.user.userName);
    formData.append('chtrmUserName',`${this.user.fname} ${this.user.lname}`);
    formData.append('chtrmId', this.currentChat.room.chtrmId);
    formData.append("chtrmFile", file);
    this.http.post(`${environment.oldv8}/chat/uploadfile`,formData).toPromise();

  }

  async chatNew(chtrmMembers) {
    if (!chtrmMembers.includes(this.user.userName)) {
      chtrmMembers.push(this.user.userName);
    }
    const newChatId:any = await this.http.post(`${environment.oldv8}/chat/room`,{
      chtrmMembers: chtrmMembers.join(","),
      chtrmOwner: this.user.userName,
      chtrmOwnerName: `${this.user.fname} ${this.user.lname}`
    }).toPromise();
    console.log(newChatId);
    if (newChatId.chtrmId) {
      return newChatId.chtrmId;
    } else {
      return 0
    }
  }
}
