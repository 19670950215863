import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { IonContent, ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, OnDestroy {

  @ViewChild('chatModalContent', { static: false }) content: IonContent;

  private chatEventSubscription: Subscription;
  public newMsg = "";

  file: File;

  constructor(
    public userSrvc: UserService,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    this.chatEventSubscription = this.userSrvc.chatEvent.subscribe((data) => {
      console.log('Event received:', data);
      // Handle the event data here
      
      setTimeout(() => {
        this.content.scrollToBottom(300);
      }, 100);

    });

    setTimeout(() => {
      this.content.scrollToBottom(300);
    }, 1000);

  }

  ngOnDestroy() {
    if (this.chatEventSubscription) {
      this.chatEventSubscription.unsubscribe();
    }
  }  

  close() {
    this.modalCtrl.dismiss();
  }

  sendMsg() {
    this.userSrvc.chatSendMessage(this.newMsg);
    this.newMsg = "";
  }

  onFileChange(event) {

    Swal.fire({
      heightAuto: false,
      title: 'Upload file',
      text: "Please confirm to upload file to chat",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Upload it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.userSrvc.chatSendFile(event.target.files[0]);
      }
    });

  }

}
