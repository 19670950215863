import { Component, QueryList, ViewChildren } from '@angular/core';
import { IonAccordionGroup, ModalController } from '@ionic/angular';
import { UserService } from './services/user.service';
import { OpeneyeupdateService } from './services/openeyeupdate.service';

import { environment } from 'src/environments/environment';
import { ChatroomsComponent } from './components/chatrooms/chatrooms.component';
import { ChatComponent } from './components/chat/chat.component';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  //get access to the accordion group accordionGroupAdminMenu
  @ViewChildren('accordionGroupMenu')
  accordions: QueryList<IonAccordionGroup>;

  AppVersion = '0.0.0';

  constructor(
    public userSrvc: UserService,
    private openupdateSrvc: OpeneyeupdateService,
    private modalCtrl: ModalController
  ) {
    this.AppVersion = environment.version;
  }

  doLogout() {
    this.userSrvc.logout();
  }

  toggleAccordion = (exclude: string = null) => {
    this.accordions.map((accordionGrp) => {
      const nativeEl = accordionGrp;
      if (nativeEl.value !== exclude) {
        nativeEl.value = undefined;
      }
    });
  };

  updateApp() {
    document.location.reload();
  }

  async showChatRooms() {

    const modal = await this.modalCtrl.create({
      component: ChatroomsComponent
    });
    this.userSrvc.chatModal = "chatRooms";
    await modal.present();

    const { data } = await modal.onDidDismiss();
    if (data) {
      if (data.goToChat) {

        await this.userSrvc.chatGetRoom(data.goToChat);
        const modal2 = await this.modalCtrl.create({
          component: ChatComponent
        });
        this.userSrvc.chatModal = "chat";
        await modal2.present();
        await modal2.onDidDismiss();
        this.userSrvc.chatCloseRoom();
        this.userSrvc.chatModal = "none";
      }
    } else {
      this.userSrvc.chatModal = "none";
    }
  }
}
