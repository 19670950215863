import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { ModalController } from '@ionic/angular';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-employeelogin',
  templateUrl: './employeelogin.component.html',
  styleUrls: ['./employeelogin.component.scss'],
})
export class EmployeeloginComponent implements OnInit {
  data = { username: '', password: '' };

  constructor(
    private userSrvc: UserService,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {}

  async loginForm(form: NgForm) {
    const result = await this.userSrvc.login(
      this.data.username,
      this.data.password
    );

    if (result) {
      this.modalCtrl.dismiss();
    }
  }
}
