import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UserService } from 'src/app/services/user.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-chatrooms',
  templateUrl: './chatrooms.component.html',
  styleUrls: ['./chatrooms.component.scss'],
})
export class ChatroomsComponent implements OnInit {

  newChatMembers:any = [];

  constructor(
    public userSrvc: UserService,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {}

  close() {
    this.modalCtrl.dismiss();
  }

  async goToChat(data) {
    this.modalCtrl.dismiss({
      goToChat: data.chtrmId
    });
  }

  async newChat() {
    if (this.newChatMembers.length == 0) {
      Swal.fire({
        heightAuto: false,
        icon: 'info',
        title: 'Select one member at least to start a new chat'
      });
    } else {
      const members = this.newChatMembers.map(item => item.code);
      const chtrmId = await this.userSrvc.chatNew(members);
      if (chtrmId == 0) {
        Swal.fire({
          heightAuto: false,
          icon: 'error',
          title: 'Unable to start a new chat'
        });  
      } else {
        this.goToChat({chtrmId});
      }
    }
  }

}
